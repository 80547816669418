import warning from './warning.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={warning} className="warning" alt="warning"/>
        <h3>Site em Construção</h3>
      </header>
    </div>
  );
}

export default App;
